import React, { useState } from "react"
import BackToTop from "../components/BackToTop"
import BookingOnline from "../components/BookingOnline"
import Layout from "../components/Layout"
import { Grid, Box } from "@material-ui/core"
import { Formik, Form } from "formik"
import Input from "../components/FormControl/Input"
import FormikSelect from "../components/FormControl/Selcet"
import FormikTextArea from "../components/FormControl/FormikTextArea"
import GoogleRecaptcha from "../components/FormControl/GoogleRecaptcha"
import * as yup from "yup"
import { graphql } from "gatsby"
import axios from "axios"
import Seo from "../components/Seo"
const Contact = ({ data }) => {
  const [policyCheck, setPolciyCheck] = useState(false)
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const [notifyOffer, setNotifyOffer] = useState(false)
  const { title, pressEmail, phoneNumber, formTitle, email } =
    data.sanityGetinTouch
  return (
    <>
      <Seo
        title={"Contact-us Page"}
        description={
          "Here customer's can query about room.And Connect with hotel team/staff."
        }
      />
      <Layout header5>
        <div className="header_bottom special-_5">
          <div className="container">
            <BackToTop />
            <BookingOnline />
          </div>
        </div>
        <div className="get__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="get_blk">
                  <h3>{title}</h3>
                </div>
                <div className="get_link">
                  <ul>
                    <li>
                      <a href={`tel:${phoneNumber}`}>Call: {phoneNumber}</a>
                    </li>
                    <li>
                      <a href={`mailto:${email}`}>E-mail: {email}</a>
                    </li>
                    <li>
                      <a href={`mailto:${pressEmail}`}>Press: {pressEmail}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_section">
          <div className="container">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                state: "",
                country: "",
                isuueOrQuestion: "",
                noOfNights: "",
                checkInDate: "",
                noOfChildrens: "",
                amount: "",
                noOfAdults: "",
                roomtype: "",
                commentOrQuestion: "",
                policyCheck: policyCheck,
                notifyOffer: notifyOffer,
              }}
              validationSchema={yup.object({
                firstName: yup.string().required("This is required field"),
                lastName: yup.string(),
                email: yup.string().email("Please enter valid email"),
                phone: yup.string().required("This field is required"),
                state: yup.string(),
                country: yup.string(),
                isuueOrQuestion: yup.string().required("Please select one"),
                noOfNights: yup.string().required("Please select one"),
                checkInDate: yup.string().required("This is required field"),
                noOfChildrens: yup.string().required("Please select one"),
                amount: yup.string().required("Please select one"),
                noOfAdults: yup.string().required("Please select one"),
                roomtype: yup.string().required("Please select one"),
                commentOrQuestion: yup
                  .string()
                  .max(500, "Max. 500 characters are allowed")
                  .required("This is required field"),
              })}
              onSubmit={values => {
                const res = axios
                  .post(`https://formspree.io/f/xrgjdeak/`, {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phone: values.phone,
                    state: values.state,
                    country: values.country,
                    isuueOrQuestion: values.isuueOrQuestion,
                    noOfNights: values.noOfNights,
                    noOfChildrens: values.noOfChildrens,
                    amount: values.amount,
                    noOfAdults: values.noOfAdults,
                    roomtype: values.roomtype,
                    commentOrQuestion: values.commentOrQuestion,
                    policyCheck: values.policyCheck,
                    notifyOffer: values.notifyOffer,
                  })
                  .then(res => {})
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contact_title">
                        <lable>{formTitle}</lable>
                      </div>
                    </div>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Input
                        placeholder={"First name"}
                        name="firstName"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input
                        placeholder={"Last name"}
                        name="lastName"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input placeholder={"Email"} name="email" type="text" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input placeholder={"Phone"} name="phone" type="text" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input placeholder={"State"} name="state" type="text" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input
                        placeholder={"Country"}
                        name="country"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSelect
                        items={[
                          {
                            label:
                              "Select the type of issue or question that you have",
                            value: "",
                          },
                          {
                            label: "Hotel reservations",
                            value: "Hotel reservations",
                          },
                          {
                            label: "Restaurant reservations",
                            value: "Restaurant reservations",
                          },
                          {
                            label: "Spa reservations",
                            value: "Spa reservations",
                          },
                          { label: "Group booking", value: "Group booking" },
                          {
                            label: "Private dining event",
                            value: "Private dining event",
                          },
                          {
                            label: "Suggestions/Feedback",
                            value: "Suggestions/Feedback",
                          },
                          {
                            label: "General inquiry",
                            value: "General inquiry",
                          },
                        ].map(item => {
                          return {
                            value: item.value,
                            label: item.label,
                          }
                        })}
                        name="isuueOrQuestion"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikSelect
                        items={[
                          { label: "No. Of Nights", value: "" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                        ].map(item => {
                          return {
                            value: item.value,
                            label: item.label,
                          }
                        })}
                        name="noOfNights"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input
                        placeholder={"Date"}
                        name="checkInDate"
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikSelect
                        items={[
                          { label: "No. Of Adults", value: "" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                        ].map(item => {
                          return {
                            value: item.value,
                            label: item.label,
                          }
                        })}
                        name="noOfAdults"
                        required={true}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                      <FormikSelect
                        items={[
                          { label: "Amount", value: "" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                        ].map(item => {
                          return {
                            value: item.value,
                            label: item.label,
                          }
                        })}
                        name="amount"
                        required={true}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <FormikSelect
                        items={[
                          { label: "No. Of Children", value: "" },
                          { label: "0", value: "0" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                        ].map(item => {
                          return {
                            value: item.value,
                            label: item.label,
                          }
                        })}
                        name="noOfChildrens"
                        required={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormikSelect
                        items={[
                          { label: "Room Type", value: "" },
                          { label: "1", value: "1" },
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                        ].map(item => {
                          return {
                            value: item.value,
                            label: item.label,
                          }
                        })}
                        name="roomtype"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextArea
                        placeholder={"Enter your comments or questions"}
                        name="commentOrQuestion"
                        rows={8}
                        required={true}
                      />
                    </Grid>
                    <Box mt={4} mb={4} pl={2}>
                      <Grid item xs={12}>
                        <GoogleRecaptcha setCaptchaVerify={setCaptchaVerify} />
                      </Grid>
                    </Box>
                  </Grid>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="checkbox_blk">
                        <div className="custom_check_box">
                          <label className="containerr">
                            <span>
                              {" "}
                              I accept the Terms and Conditions and the Privacy
                              and Personal Data Policy, which is an integral
                              part thereof
                            </span>
                            <input
                              type="checkbox"
                              name="policyCheck"
                              value={policyCheck}
                              onChange={e => e.target.value}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="containerr">
                            <span>
                              {" "}
                              Please notify me of any special offers or
                              promotions
                            </span>
                            <input
                              type="checkbox"
                              name="notifyOffer"
                              value={notifyOffer}
                              onChange={e => e.target.value}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div
                          className={
                            !captchaVerify ? "google_rc disable" : "google_rc"
                          }
                        >
                          <button type="submit" disabled={!captchaVerify}>
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
export const contactData = graphql`
  query BlogQuery {
    sanityGetinTouch {
      title
      pressEmail
      phoneNumber
      formTitle
      email
    }
  }
`
