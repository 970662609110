import React from "react"
import { useFormikContext, useField } from "formik"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  inputFieldBorder: {
    "& .MuiFilledInput-inputMultiline": {
      padding: "12px 12px 16px",
      fontSize: "25px",
      height: "187px",
    },
    "& .MuiFilledInput-root": {
      background: "#F0F0F0",
      borderRadius: "10px",
      padding: "8px",
      color: "#646464",
    },
    "& :before": {
      borderBottom: "none",
    },
    "& :after": {
      borderBottom: "none",
    },
    "& :hover:before": {
      borderBottom: "none",
    },
  },
}))

const FormikTextArea = props => {
  const { label, name, placeHolder, required, className, ...others } = props
  const [field, meta] = useField(name)
  const ErrorText = meta.error && meta.touched ? meta.error : ""
  const classes = useStyles()

  return (
    <TextField
      autoComplete="off"
      helperText={ErrorText}
      error={!!ErrorText}
      variant="filled"
      name={name}
      multiline
      rowsMax={10}
      fullWidth
      className={`${className} ${classes.inputFieldBorder}`}
      InputLabelProps={{ shrink: true }}
      {...field}
      {...others}
    />
  )
}

export default FormikTextArea
