import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

const GoogleRecaptcha = ({ setCaptchaVerify }) => {
  const handleChange = value => {
    if (value) {
      setCaptchaVerify(true)
    }
  }
  const handleExpire = () => {
    setCaptchaVerify(false)
  }
  return (
    <ReCAPTCHA
      sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
      onChange={handleChange}
      onExpired={handleExpire}
    />
  )
}
export default GoogleRecaptcha
