import React from "react"
import { useField } from "formik"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  inputFieldBorder: {
    "& .MuiFilledInput-root": {
      background: "#F0F0F0",
      borderRadius: "10px",
      height: "68px",
      fontSize: "25px",
      padding: "0 8px 16px",
    },
    "& :before": {
      borderBottom: "none",
    },
    "& :after": {
      borderBottom: "none",
    },
    "& :hover:before": {
      borderBottom: "none",
    },
  },
}))

const Input = props => {
  const {
    name,
    value,
    placeHolder,
    className,
    maxLength,
    required,
    endAdornment,
    type,
    ...others
  } = props
  const [field, meta] = useField(name)
  const ErrorText = meta.error && meta.touched ? meta.error : ""
  const classes = useStyles()

  return (
    <TextField
      autoComplete="off"
      helperText={ErrorText}
      error={!!ErrorText}
      variant="filled"
      name={name}
      type={type === "date" ? "text" : type}
      inputProps={{ maxLength: maxLength }}
      endAdornment={endAdornment}
      fullWidth
      FormHelperTextProps={{
        placeholder: placeHolder,
      }}
      onFocus={e => (e.target.type = type === "date" && type)}
      onBlur={e => (e.target.type = type === "date" && "text")}
      className={`${className} ${classes.inputFieldBorder}`}
      InputLabelProps={{ shrink: true, className: "test-label" }}
      {...field}
      {...others}
    />
  )
}

export default Input
