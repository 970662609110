import Select from "@material-ui/core/Select"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Field } from "formik"
import { FormHelperText } from "@material-ui/core"
import { MenuItem } from "@material-ui/core"
import { ErrorMessage } from "formik"
import { FormControl } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    textTransform: "capitalize",
  },
  formControl: {
    minWidth: "100%",
    background: "#F0F0F0",
    borderRadius: "10px",
    padding: "12px 16px",
    marginTop: "0px",
    height: "68px",
    fontSize: "25px",
    "& .MuiInput-underline:before": {
      border: "none",
    },
    ["@media (max-width:767px)"]: {
      height: "auto",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    "& .MuiSelect-select.MuiSelect-select": {
      margin: "6px 8px 0 4px",
      color: "#646464",
      fontSize: "25px",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      lineHeight: "29px",
    },
  },
}))

const MaterialUISelect = ({
  children,
  name,
  errorString,
  value,
  onChange,
  onBlur,
  required,
}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <FormControl className={classes.formControl} required={required}>
        <Select
          name={name}
          onChange={onChange}
          displayEmpty
          placeholder="Select Options"
          onBlur={onBlur}
          value={value}
          className={classes.select}
          fullWidth
        >
          {children}
        </Select>
      </FormControl>

      <FormHelperText style={{ color: "red", marginLeft: "18px" }}>
        {errorString}
      </FormHelperText>
    </React.Fragment>
  )
}

const FormikSelect = props => {
  const { label, name, placeHolder, className, items, required } = props
  const classes = useStyles()
  return (
    <>
      <Field
        images={props.images}
        name={name}
        as={MaterialUISelect}
        displayEmpty
        errorString={<ErrorMessage name={name} />}
        required={required}
      >
        {items.map((item, index) => {
          if (index === 0 && item.value === "none") {
            return (
              <MenuItem key={index} className={classes.placeholder}>
                <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                  {item.label}
                </div>
              </MenuItem>
            )
          } else {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )
          }
        })}
      </Field>
    </>
  )
}

export default FormikSelect
